import React, { useContext, useEffect, useState } from 'react';
import API from 'dt-cvm-api';
import moment from 'moment';

import StorageShim from 'node-storage-shim';

import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import {
  checkForEmptyObject, isEmptyObject, isNullEmptyUndefined, isNullOrUndefined
} from '../../helper/validationHelper';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../CVM/CVM';
import Vehicle from '../Details/Vehicle/Vehicle';
import HeaderButton from './HeaderButton';
import getModalData from '../../helper/modalDataHelper';
import Button from '../../UIComponents/Button/Button';
import SearchButton from '../../UIComponents/Button/SearchButton';
import { CustomerCreateButton } from '../Details/Search/SearchByCustomer';
import {
  getFormattedInspectionData, validTreadFields,
} from '../../helper/vehicleFormValidation';
import { APIRouter } from '../../Data/APIRouter';
import {
  countryCodeOptions,
  countryOptions,
  defaultInspectionQuery,
  mockInspectionData,
  objectsAreEqual,
  setCachedCVData
} from '../../helper/baseDataHelper';
import { deepObjectCopy } from '../../helper/CustomerHelper';

const localStorage = globalThis.localStorage || new StorageShim();
const sessionStorage = globalThis.sessionStorage || new StorageShim();

// Exists primarily to group button pair.
const ButtonSet = styled.div`
  display: flex;
`;
const SelectCustomerButton = styled(HeaderButton)`
  ${({ searchOrigin, truncatedWidth }) => (searchOrigin === constants.SEARCH_ORIGIN_CUSTOMER
    ? 'width: 335px;'
    : truncatedWidth ? 'width: 250px;' : 'width:334px;')}
  ${({ buttonName }) => (buttonName === constants.BACK_TO_CUSTOMER_SUMMARY || buttonName === constants.BACK_TO_VEHICLES
    ? 'width: 300px;' : 'width: 200px')};
`;
const SummaryHeaderButton = styled(HeaderButton)`
  font-family: ${theme.fontFamily.bold};
  ${({ buttonName }) => (buttonName === constants.SELECT_CUSTOMER
    ? 'width: 200px;'
    : 'width: 300px;')};
`;

const ReturnToSearchResultsButton = styled(SummaryHeaderButton)`
  ${({
    isDisabled, buttonName, theme, buttonType
  }) => (buttonName === constants.BACK_TO_SEARCH_RESULTS && isDisabled ? `
        color: ${theme.colors.lightGray};
        background-color: ${theme.colors.searchGray};
        cursor: default;
        pointer-events: none` : '')
}
`;

const ApplyVTVorAircheckEditsButton = styled(Button)`
  width:${({ isInspection }) => (isInspection ? '200px;' : '300px;')} ;
  border: 0;
  border-radius: 5px;
  white-space: nowrap;
  padding: 0;
  margin:0;
  ${({ buttonName, isDisabled }) => (buttonName && !isDisabled
    ? `background-color: ${theme.colors.green}; color:${theme.colors.white}`
    : `background-color: ${theme.colors.searchGray}; color:${theme.colors.lightGray}; pointer-events: none;`)};
  ${({ isVisible, buttonName }) => (buttonName && isVisible
    ? 'display: block;' : 'display: none')};
`;


const BackToSearchButton = styled(SummaryHeaderButton)``;

const CustomerCreateHeaderButton = styled(CustomerCreateButton)`
  height: 50px;
  width: 260px;
  white-space: nowrap;
  padding: 0;
  margin-left: 20px;
`;

const ApplyCustomerVehicleButton = styled(HeaderButton)`
    font-size: 16px;
    width: 300px;
    ${({ isDisabled }) => (isDisabled && 'pointer-events: none')};
      ${({ isVisible, buttonName }) => (buttonName && isVisible
    ? 'display: block;' : 'display: none;')};
`;

const SearchHeaderButtons = () => {
  const {
    searchOrigin,
    setSearchOrigin,
    moduleName,
    setModuleName,
    setIsModalOn,
    modalDetails,
    setModalDetails,
    csrSelected,
    customerInfo,
    didSkipResults,
    selectedVehicle,
    showDeactivatedVehicles,
    setShowDeactivatedVehicles,
    dvrSelected,
    setDVRSelected,
    vtvSelected,
    aircheckSelected,
    setVtvSelected,
    vtvCanSave,
    setVtvCanSave,
    setAircheckSelected,
    setIsToggle,
    vehSelected,
    previousOrigin,
    customerUpdate,
    setCSRSelected,
    setVehSelected,
    setErrorMessageObj,
    setApplyCustomerVehicleError,
    setLoader,
    setSelectedVehicle,
    transactionCustomer,
    isManualVtv,
    setIsManualVtv,
    setCustomerInfo,
    setSearchQuery,
    searchQuery,
    filter,
    filtersTurnedOn,
    setFilter,
    setFilters,
    setSelectedDate,
    setFiltersTurnedOn,
    setSelectedValue,
    setCurrentCV,
    isCVUpdated,
    setIsCVUpdated
  } = useContext(CVMContext);


  const isBlocked = !isNullOrUndefined(customerInfo?.blockedReasonCode) &&
  !isNullOrUndefined(customerInfo.postingBlock);
  const modalSet = () => {
    const isCustomerSearchModule = moduleName === constants.MODULE_CUSTOMER_SEARCH;
    const isVehicleSearchModule = moduleName === constants.MODULE_VEHICLE_SEARCH;
    const isCustomerSummaryModule = moduleName === constants.MODULE_CUSTOMER_SUMMARY;
    const isCustomerVehicles = moduleName === constants.MODULE_CUSTOMER_VEHICLES;
    if (moduleName === constants.MODULE_SEARCH) {
      setModalDetails(getModalData(constants.CLEAR_SEARCH_MODAL));
    }
    if (isCustomerSearchModule || isVehicleSearchModule || isCustomerSummaryModule || isCustomerVehicles) {
      setModalDetails(getModalData(constants.NEW_SEARCH_MODAL, moduleName));
    }
    setIsModalOn(true);
  };
  const customerCreateModule = () => {
    setErrorMessageObj({});
    setCSRSelected(null);
    setVehSelected(null);
    setModuleName(constants.MODULE_CREATE_CUSTOMER);
  };
  const reactivateVehicle = () => {
    if (isBlocked) {
      return;
    }
    if (!dvrSelected) {
      setIsModalOn(false);
      return;
    }
    if (moduleName === constants.MODULE_CUSTOMER_VEHICLES || moduleName === constants.MODULE_CUSTOMER_SUMMARY || constants.DEACTIVATE_VEHICLE_LABEL) {
      const modalData = getModalData(constants.MODAL_TYPE_REACTIVATE_VEHICLE);
      modalData.modalBody = <Vehicle vehicle={dvrSelected} onSelect={() => { }} />;
      modalData.customerId = dvrSelected.customerId;
      setModalDetails(modalData);
    }
    setIsModalOn(true);
  };

  const backToSearchResults = () => {
    if (moduleName !== constants.MODULE_CUSTOMER_SUMMARY) return;
    setModalDetails(getModalData(constants.CUSTOMER_BACK_TO_SEARCH_MODAL));
    setIsModalOn(true);
    setDVRSelected(null);
  };
  const backToCustomerSummary = () => {
    setShowDeactivatedVehicles(false);
    setDVRSelected(null);
    // NOTE: Accounts for override of previous selected inpsection, in 2 routes
    if ((previousOrigin.origin === constants.SEARCH_ORIGIN_VTV || previousOrigin.origin === constants.SEARCH_ORIGIN_AIRCHECK ||
      (previousOrigin.searchQuery?.phoneNumber && previousOrigin?.data))
      && !isEmptyObject(previousOrigin?.data || {})) {
      if (previousOrigin.origin === constants.SEARCH_ORIGIN_VTV || previousOrigin.data?.reason === 'service') {
        setVtvSelected(previousOrigin.data);
      } if (previousOrigin.origin === constants.SEARCH_ORIGIN_AIRCHECK || previousOrigin.data?.reason === 'aircheck') {
        setAircheckSelected(previousOrigin.data);
      }
    } else if (moduleName !== constants.MODULE_EDIT_CUSTOMER) {
      setVtvSelected(null);
      setAircheckSelected(null);
    }
    setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
  };
  const backToSearch = () => {
    if (moduleName === constants.MODULE_CREATE_CUSTOMER || searchOrigin === constants.SEARCH_ORIGIN_CREATE_CUSTOMER) {
      setModuleName(constants.MODULE_SEARCH);
      setSelectedVehicle(null);
      setSearchOrigin(null);
      setErrorMessageObj({});
      setAircheckSelected(null);
    } else {
      setModuleName(constants.MODULE_SEARCH);
      setSearchOrigin(null);
      setErrorMessageObj({});
    }
    setCustomerInfo({});
    setCSRSelected(null);
  };

  const selectCustomer = () => {
    csrSelected && setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
  };

  const selectVehicle = () => {
    vehSelected && setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
  };

  const getOverRideVehicleModal = (selectedResult, isVTV) => {
    let isNotOverride = true;
    if (!previousOrigin.data && selectedVehicle?.vehicleDetails) {
      const {
        year, make, model
      } = selectedVehicle.vehicleDetails;
      const  inspectionVehicle  = vtvSelected ? vtvSelected.vehicle : aircheckSelected.vehicle;
      isNotOverride = (year.toString() === inspectionVehicle?.year && make === inspectionVehicle?.make && model === inspectionVehicle?.model);
    }
    if (!isNullOrUndefined(selectedResult)) {
      setIsToggle(false);
      if ((previousOrigin?.data && previousOrigin.data?.inspectionId !==  (vtvSelected ? vtvSelected.inspectionId : aircheckSelected.inspectionId)) || !isNotOverride) {
        const modalDetails = getModalData(constants.MODAL_OVERRIDE_SELECTED_VEHICLE);
        modalDetails.subtitle = isVTV
          ? constants.MODAL_OVERRIDE_SELECTED_VEHICLE_SUBTITLE_VTV
          : constants.MODAL_OVERRIDE_SELECTED_VEHICLE_SUBTITLE_AIRCHECK;
        modalDetails.cancelButton = isVTV
          ? constants.MODAL_OVERRIDE_SELECTED_VEHICLE_CANCEL_BUTTON_VTV
          : constants.MODAL_OVERRIDE_SELECTED_VEHICLE_CANCEL_BUTTON_AIRCHECK;
        setModalDetails(modalDetails);
        setIsModalOn(true);
      } else {
        vtvSelected ? setVtvSelected(vtvSelected)
          : setAircheckSelected(aircheckSelected);
        setSelectedVehicle(null);
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      }
    }
  };

  const gkCustomerPush = (customer) => {
    // TO DO: This will need to be restructured to fulfill requirements for GK push
    // Will currently set whatever customer is passed primary/alternate into iniitial structure
    const phoneCollection = [];
    const phones = [customer?.phoneNumber, customer?.mobileNumber, customer?.workNumber];
    phones.forEach((phone, i) => {
      if (phone) {
        phoneCollection.push({
          phoneItem: {
            phoneType: i === 0 ? constants.HOME_PHONE : i === 1 ? constants.MOBILE_PHONE : constants.WORK_PHONE,
            phoneNumber: phone,
          }
        });
      }
    });
    customer.phoneCollection = phoneCollection;

    // strip company name from object if is present and is B2C (handles legacy customers)
    const empId = customer?.alternateIdentifierCollection?.find(ai => ai.alternateIdentifierItem?.name === 'EMPLOYEE ID');
    if (customer.customerType === constants.STANDARD_CUSTOMER_TYPE && empId) {
      customer.companyName = constants.EMPTY_STRING;
    }

    return customer;
  };

  const publishCVData = async (e, byPassedData) => {
    let data;

    if (!byPassedData) {
      data = await prepDataForSend();
      if (API.utils.notValid(data)) {
        return;
      }
    } else {
      data = byPassedData;
    }

    //  Store the data in the CV cache so that getTransactionCV call results can
    //  potentially reload the customer & vehicle without talking to the server.
    //  NOTE: this data should mirror the 'currentCV' data structure.
    const { SRPMICNumber, ...customerWithoutSRPMICNumber } = customerInfo;

    setCachedCVData({ customer: customerWithoutSRPMICNumber, vehicle: selectedVehicle, inspection: vtvSelected || aircheckSelected });

    //  Format it like how GK likes it
    const formattedData = await API.transforms.transformGKData(
      data.customer, data.vehicle,
      localStorage.getItem('schemaVersion') || constants.DEFAULT_SCHEMA_VERSION,
    );

    // CVM-1793
    // Removed B2B phone extensions for PB since they aren't ready to handle phone extensions,
    // so I guess we have to do it :)
    if (formattedData) {
      formattedData?.customer.businessPrimaryPurchasingPhoneCollection?.forEach(item => {
        delete item.businessPhoneExtension;
      });
    }


    //  Are we running in GK?
    if (API.GK.hasPOS()) {
      console.log('Sending data to GK POS');
      return sendDataToGK(formattedData);
    }

    //  Are we running in an iframe?
    const runningInIFrame = window.location !== window.parent.location;
    if (runningInIFrame) {
      console.log('Sending data to iframe');
      return sendDataToListener(formattedData);
    }
    console.log('No data target (POS or iframe)');

  };

  const prepDataForSend = async () => {
    // Customer conversion if the transaction customer info changed
    let customer = deepObjectCopy(customerInfo);
    if (transactionCustomer) {
      if (typeof transactionCustomer.state === 'object') transactionCustomer.state = transactionCustomer.state.value;
      // Needs the country code to be set as "US" vs "USA" this will be resolved in refactor
      if (typeof transactionCustomer.country === 'object') {
        transactionCustomer.country = transactionCustomer.country.value === countryOptions[0].value
          ? countryCodeOptions[0].value : transactionCustomer.country.value;
      }
      customer = { ...customer, ...transactionCustomer };
    }
    customer = gkCustomerPush(customer);
    //
    let vehicle = null;
    if (selectedVehicle) {
      vehicle = selectedVehicle;
      if (!isNullEmptyUndefined(selectedVehicle?.inspection)) {
        vehicle.inspection = selectedVehicle.inspection;
      }
    }

    const requiredFields = {
      '07': 'year',
      '08': 'make',
      '09': 'model',
      '10': 'licensePlateNumber',
      '11': 'licenseState',
      '12': 'vin',
    };
    const errors = customerInfo?.characteristicCollection?.reduce((errors, cc) => {
      const field = requiredFields[cc.characteristicItem.code];
      if (field && isNullEmptyUndefined(vehicle.vehicleDetails[field])) {
        if (!errors.includes(field)) {
          errors.push(field);
        }
      }
      console.log(errors);
      return errors;
    }, []) || [];

    if (errors.length <= 3) {
      if (vehicle.vehicleDetails.isCarryOut && (errors.includes('vin') || (errors.includes('licenseState') && errors.includes('licensePlateNumber')) || errors.includes('licensePlateNumber'))) {
        setFieldsForCarryOut(vehicle);
        return { customer, vehicle };
      }
    }

    const proceedWithCarryOut = async () => {
      vehicle.vehicleDetails.isCarryOut = true;
      setFieldsForCarryOut(vehicle);
      await publishCVData(null, { customer, vehicle });
      setIsModalOn(false);
    };

    if (errors.length > 0) {
      // handles carry-out logic to complete the missing fields or bypass and push to GK without entering required fields
      if (errors.includes('vin') || errors.includes('licenseState') || errors.includes('licensePlateNumber')) {
        setApplyCustomerVehicleError(true);
      } else {
        setApplyCustomerVehicleError(true);
      }
      return;
    }

    if (vehicle.vehicleDetails.isCarryOut) {
      setFieldsForCarryOut(vehicle);
    }

    return { customer, vehicle };
  };

  const setFieldsForCarryOut = (vehicle) => {
    // set hard-coded values for carry out
    vehicle.vehicleDetails.vin = 'carryout';
    vehicle.vehicleDetails.license = 'carryout';
    vehicle.vehicleDetails.licenseState = 'AZ';
    vehicle.vehicleDetails.licenseCountry = 'USA';
  };

  const sendDataToGK = async (data) => {
    if (data.customer && data.vehicle) {
      try {
        await APIRouter('GK', 'applyCustomerVehicle', data, setLoader);
        setCurrentCV({ customer: customerInfo, vehicle: selectedVehicle, inspection: vtvSelected || aircheckSelected });
        setIsCVUpdated(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const sendDataToListener = async (data) => {

    if (data.customer && data.vehicle) {
      try {
        window.parent.postMessage(data, '*');
      } catch (e) {
        console.error(e);
      }
    }
  };

  const applyVTVAircheck = async () => {
    // formats the data to be in structure inspection JSON needs
    const inspectionData = getFormattedInspectionData(vtvCanSave, selectedVehicle, customerInfo);
    const res = await APIRouter('VTV', !isManualVtv ? 'updateVTVInspection' : 'addVTVInspection', { ...inspectionData }, setLoader);
    setIsManualVtv(false);
    setSelectedVehicle(null);
    setVtvCanSave(false);
    if (vtvSelected || isManualVtv) {
      setVtvSelected({ inspectionId: !isManualVtv ? res.inspectionId : res.id });
    } else {
      setAircheckSelected({ inspectionId: !isManualVtv ? res.inspectionId : res.id });
    }
    setCustomerInfo({ SRPMICNumber: modalDetails?.data?.SRPMICNumber || constants.EMPTY_STRING, taxExempt: customerInfo?.taxExempt || {} });

  };

  const clearTheFilters = () => {
    // clearFilter logic
    setFilters(0);
    setFiltersTurnedOn({ isRadioOn: false, isCalendarOn: false });
    setSelectedValue('both');
    setFilter(filter);

    // Handle date change logic if applicable
    if (filtersTurnedOn.isCalendarOn) {
      setSelectedDate(new Date());
    }
  };

  useEffect(() => {
    clearTheFilters();
  }, []);

  const refreshVTVAircheck = async () => {
    clearTheFilters();
    const parsedDate = moment(searchQuery?.startDate);
    // Format the parsed date object
    const formattedDateString = parsedDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    // Convert the formatted date string to a JavaScript Date object for defaultInspectionQuery
    const date = new Date(formattedDateString);
    const refreshSearchQuery = defaultInspectionQuery(date);
    setSearchQuery(refreshSearchQuery);
  };

  const selectVTVAircheck = () => {
    switch (searchOrigin) {
      case constants.SEARCH_ORIGIN_VTV:
        getOverRideVehicleModal(vtvSelected, true);
        break;
      case constants.SEARCH_ORIGIN_AIRCHECK:
        getOverRideVehicleModal(aircheckSelected, false);
        break;
      default:
        break;
    }
  };
  const closeDeactivatedVehicles = () => {
    setShowDeactivatedVehicles(false);
    setDVRSelected(null);
    const isCustomerEditModule = moduleName === constants.MODULE_EDIT_CUSTOMER;
    const isSearchOriginVTVAircheck = searchOrigin === constants.SEARCH_ORIGIN_VTV || searchOrigin === constants.SEARCH_ORIGIN_AIRCHECK;
    if (isCustomerEditModule) {
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    }
    if (isSearchOriginVTVAircheck) {
      vtvSelected && setVtvSelected(null);
      aircheckSelected && setAircheckSelected(null);
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    }
  };
  const backToVehicles = () => {
    setDVRSelected(null);
    setShowDeactivatedVehicles(false);

    const isCustomerVehicleModule = moduleName === constants.MODULE_CUSTOMER_VEHICLES;
    isCustomerVehicleModule && setShowDeactivatedVehicles(false);
  };

  const displayModal = () => {
    modalDetails.renderFieldErrors();
    if (modalDetails.isValid && modalDetails.data) {
      setIsModalOn(true);
    }
  };

  const headerButtons = () => {
    switch (moduleName) {
      case constants.MODULE_SEARCH:
        return (<HeaderButton buttonName={constants.CLEAR_SEARCH} buttonClick={modalSet} />);
      case constants.MODULE_CREATE_CUSTOMER:
        return (
          <BackToSearchButton
            buttonName={constants.BACK_TO_SEARCH}
            buttonClick={backToSearch}
          />
        );
      case constants.MODULE_CUSTOMER_SEARCH:
        // Note: previous origin needs to be stored for the customer on select vtv/aircheck in summary
        if (searchOrigin === constants.SEARCH_ORIGIN_CUSTOMER
          || previousOrigin?.origin === constants.SEARCH_ORIGIN_CUSTOMER) {
          return (
            <>
              <SelectCustomerButton
                buttonName={constants.SELECT_CUSTOMER}
                isDisabled={isNullOrUndefined(csrSelected)}
                buttonClick={selectCustomer}
              />
              <CustomerCreateHeaderButton
                buttonName={constants.TITLE_CUSTOMER_CREATE}
                onButtonClick={customerCreateModule}
                isCreate
          />
              <HeaderButton buttonName={constants.EDIT_SEARCH} buttonClick={backToSearch} />
              <HeaderButton buttonName={constants.EXIT_SEARCH} buttonClick={modalSet} />
            </>
          );
        } break;

      case constants.MODULE_VEHICLE_SEARCH:
        // Note: previous origin needs to be stored for the vehicle on select vtv/aircheck in summary
        if (searchOrigin === constants.SEARCH_ORIGIN_LICENSE_PLATE
          || searchOrigin === constants.SEARCH_ORIGIN_VIN
          || (previousOrigin?.origin === constants.SEARCH_ORIGIN_LICENSE_PLATE && (searchOrigin !== constants.SEARCH_ORIGIN_VTV && searchOrigin !== constants.SEARCH_ORIGIN_AIRCHECK))
          || (previousOrigin?.origin === constants.SEARCH_ORIGIN_VIN && (searchOrigin !== constants.SEARCH_ORIGIN_VTV && searchOrigin !== constants.SEARCH_ORIGIN_AIRCHECK))) {
          return (
            <>
              <SelectCustomerButton
                buttonName={constants.SELECT_CUSTOMER}
                isDisabled={isNullOrUndefined(vehSelected)}
                buttonClick={selectVehicle}
              />
              <HeaderButton buttonName={constants.EDIT_SEARCH} buttonClick={backToSearch} />
              <HeaderButton buttonName={constants.EXIT_SEARCH} buttonClick={modalSet} />
            </>
          );
        }
        if (searchOrigin === constants.SEARCH_ORIGIN_VTV
          || searchOrigin === constants.SEARCH_ORIGIN_AIRCHECK) {
          return (
            <>
              <SelectCustomerButton
                truncatedWidth={!checkForEmptyObject(previousOrigin)}
                buttonName={constants.SELECT_CUSTOMER}
                isDisabled={isNullOrUndefined(vtvSelected) && isNullOrUndefined(aircheckSelected)}
                buttonClick={!isNullOrUndefined(vtvSelected || aircheckSelected) ? selectVTVAircheck : () => {}}
              />
              {previousOrigin?.data ||  (!isEmptyObject(previousOrigin) && !isEmptyObject(customerInfo))
                ? (
                  <SummaryHeaderButton
                    buttonName={constants.BUTTON_RETURN_TO_CUSTOMER_SUMMARY}
                    buttonClick={backToCustomerSummary}
                  />
                )
                : (
                  <>
                    <HeaderButton buttonName={constants.EXIT_SEARCH} buttonClick={modalSet} />
                    <HeaderButton buttonName={constants.VTV_AIRCHECK_REFRESH} buttonClick={refreshVTVAircheck} />
                  </>
                )}
            </>
          );
        } break;
      case constants.MODULE_CUSTOMER_SUMMARY:
        return showDeactivatedVehicles ? (
          <>
            <SelectCustomerButton
              buttonName={constants.REACTIVATE_VEHICLE}
              isDisabled={!dvrSelected || isBlocked}
              buttonClick={reactivateVehicle}
            />
            <SelectCustomerButton
              buttonName={constants.BACK_TO_CUSTOMER_SUMMARY}
              buttonClick={closeDeactivatedVehicles}
            />
          </>
        ) : (
          <>
            <ApplyVTVorAircheckEditsButton
              isInspection={vtvSelected || isManualVtv || aircheckSelected}
              buttonName={(vtvSelected || isManualVtv) ? constants.APPLY_VTV_EDITS : constants.APPLY_AIRCHECK_EDITS}
              isVisible={vtvCanSave}
              isDisabled={!validTreadFields(vtvCanSave?.tireCollection)}
              onButtonClick={applyVTVAircheck}
            />
            {searchOrigin === constants.SEARCH_ORIGIN_CREATE_CUSTOMER ? (
              <>

                <ApplyCustomerVehicleButton
                  buttonName={constants.APPLY_CUSTOMER_VEHICLE}
                  isDisabled={!selectedVehicle || selectedVehicle.vehicleDetails?.isIncomplete || !customerInfo || (customerInfo.isGKValid === false || Object.keys(customerInfo).filter(k => k === 'isGKValid')?.length === 0) || !isCVUpdated}
                  isVisible={!vtvCanSave}
                  buttonClick={selectedVehicle && !selectedVehicle.vehicleDetails?.isIncomplete && customerInfo.isGKValid ? (e) => publishCVData(e) : () => {}}
                />
                <SummaryHeaderButton
                  buttonName={constants.BACK_TO_SEARCH}
                  buttonClick={backToSearch}
            />
              </>
            ) : (
              <>
                <ApplyCustomerVehicleButton
                  buttonName={constants.APPLY_CUSTOMER_VEHICLE}
                  isDisabled={!selectedVehicle || selectedVehicle.vehicleDetails?.isIncomplete || !customerInfo || (customerInfo.isGKValid === false || Object.keys(customerInfo).filter(k => k === 'isGKValid')?.length === 0) || !isCVUpdated}
                  isVisible={!vtvCanSave}
                  buttonClick={selectedVehicle && !selectedVehicle.vehicleDetails?.isIncomplete && customerInfo.isGKValid ? (e) => publishCVData(e) : () => {}}
                />
                <ReturnToSearchResultsButton
                  buttonName={constants.BACK_TO_SEARCH_RESULTS}
                  buttonClick={backToSearchResults}
                  isDisabled={didSkipResults}
                />
                <HeaderButton buttonName={constants.EXIT_SEARCH} buttonClick={modalSet} />
              </>
            )}

          </>
        );
      case constants.MODULE_CUSTOMER_VEHICLES:
        return showDeactivatedVehicles ? (
          <>
            <SelectCustomerButton
              buttonName={constants.REACTIVATE_VEHICLE}
              isDisabled={isNullOrUndefined(dvrSelected) || isBlocked}
              buttonClick={reactivateVehicle}
            />
            <SelectCustomerButton
              buttonName={constants.BACK_TO_VEHICLES}
              buttonClick={moduleName === constants.MODULE_CUSTOMER_VEHICLES ? backToVehicles : backToCustomerSummary}
                  />
          </>
        ) : (
          <>
            <ApplyCustomerVehicleButton
              buttonName={constants.APPLY_CUSTOMER_VEHICLE}
              isDisabled={!selectedVehicle || selectedVehicle.vehicleDetails?.isIncomplete || (customerInfo.isGKValid === false || Object.keys(customerInfo).filter(k => k === 'isGKValid')?.length === 0) || !isCVUpdated}
              buttonClick={selectedVehicle && !selectedVehicle.vehicleDetails?.isIncomplete && customerInfo.isGKValid ? (e) => publishCVData(e) : () => {}}
              isVisible
            />
            <SummaryHeaderButton
              buttonName={constants.BACK_TO_CUSTOMER_SUMMARY}
              buttonClick={backToCustomerSummary}
            />
            <HeaderButton buttonName={constants.EXIT_SEARCH} buttonClick={modalSet} />
          </>
        );
      case constants.MODULE_EDIT_CUSTOMER:
        return (
          <>
            {customerInfo?.customerType === constants.STANDARD_CUSTOMER_TYPE && (
              <SearchButton
                buttonName={constants.APPLY_EDITS}
                isDisabled={!customerUpdate}
                width="200"
                onButtonClick={displayModal} />
            )}
            <SummaryHeaderButton
              buttonName={constants.BACK_TO_CUSTOMER_SUMMARY}
              buttonClick={backToCustomerSummary}/>
          </>
        );
      default:
        return null;
    }
  };
  return <ButtonSet>{headerButtons()}</ButtonSet>;
};

export default SearchHeaderButtons;

